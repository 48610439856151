import MobileDetect from 'mobile-detect'
import { mapGetters } from 'vuex'

export default {
  props: ['slug', 'record', 'type', 'id'],
  methods: {
    scroll() {
      this.autoScrollDesktop()
      let md = new MobileDetect(window.navigator.userAgent)
      let result = md.mobile()
      if (result === 'iPad' || result === 'iPhone') {
        this.autoScrollMobile()
      } else {
        this.autoScrollDesktop()
      }
    },
    autoScrollMobile () {
      const elem = document.querySelector('.ps')
      var chatHeight = document.querySelector('.chat-scroll').offsetHeight
      elem.scrollTop = chatHeight + 200
    },
    autoScrollDesktop () {
      var elem = document.querySelector('html')
      var chatArea = document.querySelector('.chat-scroll') || {}
      elem.scrollTop = chatArea.scrollHeight
    },
    startAnimation () {
      let target = this.$refs.formWrapper
      this.anime({
        targets: target,
        easing: 'easeInOutQuad',
        translateY: [
          { value: 400, duration: 200 },
          { value: 0, duration: 800 }
        ],
        opacity: [
          { value: 0, duration: 100 },
          { value: 1, duration: 400 }
        ]
      })
    },
    async endAnimation (callback) {
      let target = this.$refs.formWrapper
      await this.anime({
        targets: target,
        easing: 'easeInOutQuad',
        translateY: [
          { value: 0, duration: 100 },
          { value: 0, duration: 300 },
          { value: 400, duration: 1000 }
        ],
        opacity: [
          { value: 1, duration: 800 },
          { value: 0, duration: 400 }
        ]
      }).finished

      await callback()
    },
    iconAnimation () {
      const direction = this.data.isBot ? -100 : 100
      const icon = this.$refs.icon

      this.anime({
        targets: icon,
        translateX: [
          { value: 0, duration: 100 },
          { value: direction, duration: 100 },
          { value: 0, duration: 500 }
        ],
        opacity: [
          { value: 0, duration: 100 },
          { value: 0, duration: 100 },
          { value: 1, duration: 250 }
        ]
      })
    },
    messageAnimation () {
      const direction = this.data.isBot ? 0 : 100
      const message = this.$refs.text

      this.anime({
        targets: message,
        delay: 150,
        transformOrigin: direction,
        scale: [
          { value: 0, duration: 100 },
          { value: 0, duration: 100 },
          { value: 1, duration: 500 }
        ],
        opacity: [
          { value: 0, duration: 100 },
          { value: 0, duration: 100 },
          { value: 1, duration: 250 }
        ]
      })
    }
  },
  computed: {
    ...mapGetters({
      camaraName: 'cases/camaraName',
      guestName: 'cases/guestName'
    }),
    name () {
      return this.record && this.record.data.isBot ? this.camaraName : this.guestName
    },
    alias () {
      const name = this.name || ''
      const arrNames = name.split(' ')
      const first = arrNames.shift().charAt(0)
      const last = arrNames.pop().charAt(0) || ''
      return `${first}${last}`
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    timeHour () {
      // let today = new Date()
      // let date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
      // let time = today.getHours() + ':' + today.getMinutes() + 'h' // + ':' + today.getSeconds()
      // return date + ' - ' + time
      return ''
    }
  },
  mounted () {
    // this.isMobile ? this.autoScrollMobile() : this.autoScrollDesktop()
    this.scroll()
  }
}
